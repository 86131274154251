import React from "react"
import { Link } from "react-router-dom"
import { Facebook, Instagram } from "lucide-react";


const FacebookLink = ({ url = "https://www.facebook.com/bythebridgeptbo/"}) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-2 text-white hover:text-blue-100 font-semibold transition duration-300 bg-blue-300/50 rounded-xl"
    >
      <Facebook size={24} />
    </a>
  );
};
const InstaLink = ({ url = "https://www.instagram.com/bythebridgeptbo?igsh=MXBjZnN4ODhlb2hoZw=="}) => {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-2 text-white hover:text-orange-100 font-semibold transition duration-300 bg-pink-300/50 rounded-xl"
      >
        <Instagram />
      </a>
    );
  };

const Footer = () =>{
    return (
        <div class="w-full bg-black flex flex-row justify-between">
            <div class="basis-1/3 flex flex-col p-10 gap-4">
                <div class="text-white underline flex flex-row justify-center">
                Menu
                </div>
                <div class="text-white flex flex-row justify-center">
                    <ul class="flex flex-col gap-4">
                            <li><Link to="storeMenu">In-Store</Link></li>
                            <li><Link to="cateringMenu">Catering</Link></li>
                    </ul>
                </div>
                
            </div>
            <div class="basis-1/3 flex flex-col p-10 gap-4">
                <div class="text-white underline flex flex-row justify-center">
                    Info
                </div>
                <div class="flex flex-row justify-center">
                <FacebookLink></FacebookLink>
                </div>
                <div class="flex flex-row justify-center">
                <InstaLink></InstaLink>
                </div>
                
            </div>
            {/* <div class="flex flex-row justify-end shrink-0">
                <img class="h-32 w-60" src={require("./media/logo_white.png")}/>
            </div> */}
            <div class="flex flex-row justify-end h-max">
                <img class="w-full h-auto object-contain" src={require('./media/logo_white.png')}></img>
            </div>
        </div>
    )
}

export default Footer