import React from "react"
import MenuGrid from "./MenuGrid"
import { useLocation } from "react-router-dom"

// Ginger-Sesame-Lime Slaw with Chili-Nuts

// Rubbed Kale with Pistachio Crunch

// Grilled Red Cabbage Slaw (sesame, rice vinegar, ginger)

// Maple Balsamic Beets with red onions

// Baby Spinach, Mixed Greens and Toasted Pumpkin Seeds with Tamari-Nutch Dressing

// Sweet Soy Vermicelli Garnished with Red Pepper and Green Onions

// Creamy Sriracha-Lime Pasta (Other creamy paste options)

// Bruschetta Pasta, tomatoes, fresh basil, garlic and olive

const menuitems=[
    {name:'Chickpea', desc: 'Creamy Curried Chickpeas, Green Onion, and Cranberries'},
    {name:'Kale', desc: 'Rubbed Kale with Pistachio Crunch', img: '../media/kale.jpg'},
    {name:'Sesame Slaw', desc: 'Grilled Red Cabbage Slaw (sesame, rice vinegar, ginger)'},
    {name:'Balsamic Beets', desc: 'Maple Balsamic Beets with red onions'},
    {name: 'Nutch', desc: 'Baby Spinach, Mixed Greens and Toasted Pumpkin Seeds with Tamari-Nutch Dressing'},
    {name: 'Vermicelli', desc: 'Sweet Soy Vermicelli Garnished with Red Pepper and Green Onions'},
    {name: 'Creamy Pasta', desc: 'Creamy Sriracha-Lime Pasta (Other creamy paste options)'},
    {name: 'Bruschetta', desc: 'Bruschetta Pasta, tomatoes, fresh basil, garlic and olive'}
]
const Salads = () => {
    var price = (useLocation().pathname === '/storeMenu/salads' | useLocation().pathname === '/storeMenu')
    
    return (
        <div class="flex flex-col center">
            <div class="flex flex-row md:justify-center justify-left h-60 w-full gap-4 overflow-auto">
                <img class="h-full w-auto object-contain rounded-xl" src={require('../media/sald_bar2.jpg')}></img>
                <img class="h-full w-auto object-contain rounded-xl" src={require('../media/mixnmatch.jpg')}></img>
                <img class="h-full w-auto object-contain rounded-xl" src={require('../media/fancy_steak_salad.jpg')}></img>
            </div>
            
            {!!price && <div>
                <div class="flex flex-row justify-center">
                    <div class="font-header text-5xl text-center">Mix and Match Salad Bowls</div>
                </div>
                <div class="flex flex-row justify-center">
                    <div class="font-header font-thin text-3xl text-center">Size: Regular $12, Large $14.50, Extra Large $24</div>
                </div>
                </div>
            }
            
            <MenuGrid menuitems={menuitems}/>
        </div>
    )
}

export default Salads