import MenuGrid from "./MenuGrid"
// - Broccoli Cheddar and red onion
// - Cajun Sweet Potato and Feta
// - Smoked Salmon, Red Onion, Cream Cheese, Capers, and Fresh Dill
const menuItems = [
    {name:'Brocolli', desc:'Broccoli Cheddar and red onion'},
    {name:'Cajun', desc:'Cajun Sweet Potato and Feta'},
    {name:'Salmon', desc:'Smoked Salmon, Red Onion, Cream Cheese, Capers, and Fresh Dill'}
]
const Fritatas = () => {
    return (
        <div class="flex flex-col">
            <div class="flex flex-row justify-center w-auto h-80 gap-4 py-4 pl-4">
                <img class="h-full w-auto object-contain rounded-xl" src={require('../media/fritata.jpg')}></img>
            </div>
            <MenuGrid menuitems={menuItems}></MenuGrid>
        </div>
    )
}

export default Fritatas