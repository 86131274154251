import AddOns from "./AddOns"

const Bowls = () => {
    return (
        <div>
            <div class="flex flex-row justify-center">
                <div class="font-header text-3xl">Mix and Match Salad Bowls</div>
            </div>
            <div class="flex flex-row justify-center">
                <div class="text-xl">Size: Regular $12, Large $14.50, Extra Large $24</div>
            </div>
            <AddOns></AddOns>
        </div>
    )
}

export default Bowls