// - Chocolate Chip Oatmeal Cookie
// - Coconut Snowball – Vegan GF
// - Diablo Cookie (Chocolate, Ginger, Cinnamon, Cayenne)
// - Triple Chocolate Brownies
// - Peanut Butter Cups – Vegan GF
// - Ginger Molasses Cookies – Vegan

import MenuGrid from "./MenuGrid"


const menuItems = [
    {name:'Chocolate Chip Oatmeal Cookie'},
    {name:'Coconut Snowball – Vegan GF'},
    {name:'Diablo Cookie (Chocolate, Ginger, Cinnamon, Cayenne)'},
    {name:'Triple Chocolate Brownies'},
    {name:'Peanut Butter Cups – Vegan GF'},
    {name:'Ginger Molasses Cookies – Vegan'}
]
const Sweets = () => {
    return (
        <div>
            <div class="flex flex-row justify-center h-60 w-full gap-4 overflow-auto">
                <img class="h-full w-auto object-contain rounded-xl" src={require('../media/desserts.jpg')}></img>
            </div>
            <MenuGrid menuitems={menuItems}></MenuGrid>
        </div>
    )
}

export default Sweets