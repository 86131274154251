import MenuGrid from "./MenuGrid"

// Cajun Chicken $5

// Chili Crisp Tofu $4

// Candies Jalapeno Devilled Eggs $3

// Frittata -$5

// Drinks $2

// Sweets $2

const menuitems=[
    {name:'Cajun Chicken', desc:'$4'},
    {name:'Chili Crisp Tofu', desc:'$4'},
    {name:'Candied Jalapeno Devilled Eggs', desc: '$2'},

]
const AddOns = () => {
    return (
        <div>
            <div class="flex flex-row md:justify-center justify-left h-60 w-full gap-4 overflow-auto">
                <img class="h-full w-auto object-contain rounded-xl" src={require('../media/plated_salad.jpg')}></img>
                <img class="h-full w-auto object-contain rounded-xl" src={require('../media/deviledeggs.jpg')}></img>
                <img class="h-full w-auto object-contain rounded-xl" src={require('../media/smoky_chix.jpg')}></img>
            </div>
            <MenuGrid menuitems={menuitems}/>
        </div>
    )
}

export default AddOns