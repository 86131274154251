import React from "react"
import { Link } from "react-router-dom"

const Hours = ()=>{
    return (
        <div class="flex flex-col flex-grow">
            <div class="flex md:flex-row flex-col-reverse justify-center flex-grow md:py-20 md:px-10">
                <div class="md:basis-1/2 flex flex-col justify-center font-header">
                    <div class="flex flex-row justify-center w-full text-3xl font-bold">
                        Visit us In-Store!
                    </div>
                    <div class="flex flex-row justify-center w-full font-nav text-lg font-medium pt-4">
                        382 Water St, Peterborough
                    </div>
                    <div class="flex flex-row justify-center w-full font-nav text-lg font-medium">
                        705-775-5050
                    </div>
                    <div class="flex flex-row justify-center w-full font-nav text-lg font-normal pt-4">
                        Tuesday-Thursday
                    </div>
                    <div class="flex flex-row justify-center w-full font-nav text-lg font-normal">
                        11:00am - 2:00pm
                    </div>
                    <Link to="/storeMenu">
                    <div class="border-lime-200 border-4 flex flex-row justify-center shadow-xl bg-lime-100 hover:bg-lime-50 md:mx-32 m-10 rounded-lg mt-4 p-2 min-w-48">
                        We serve to-go lunch!
                    </div>
                    </Link>
                </div>

                <div class="flex flex-row basis-1/2 md:flex-grow justify-center w-full">
                    <img class="md:rounded-xl drop-shadow-2xl w-2/3" src={require("../media/bridgetbooth.jpeg")}/>
                </div>
            </div>
        </div>
    )
}

export default Hours