import React from "react"
import emailjs from "emailjs-com";
import CateringOrderForm from "../Components/CateringOrderForm.js";

const Option = (props) => {
    return (
    <div class="md:basis-1/2 sm:basis-full">
        <div class="bg-white rounded-xl flex flex-row border-lime-100 border-4 m-4 shadow-xl">
            <div class="p-10 font-header flex flex-col gap-4">
                    <div class="text-4xl">
                        {props.name} - ${props.cost}/Person 
                    </div>
                    <div> 
                        {props.children}
                    </div>
            </div>

        </div>
    </div>
    )
}

const Catering = ()=>{
    return (
        <div class="flex flex-col flex-grow">
            <div class="pt-10 flex flex-row justify-center w-full text-3xl font-header font-bold h-96 bg-[linear-gradient(to_bottom,rgba(50,84,44,0.0),rgba(255,255,255,1)),url('./media/fancy_steak_salad.jpg')]">
                        <div class="bg-black/75 text-white h-36 flex flex-col justify-center p-4">
                        Looking to have By the Bridge at your next event?

                        <div class="flex flex-row justify-center w-full text-lg font-medium pt-4">
                        We cater business lunches, weddings, and other occasions!
                        </div>
                        </div>
                        
            </div>
            <div class="md:px-32">
                
                <div class="text-3xl font-header font-bold">
                        Classic Menus
                </div>

                <div class="flex flex-row flex-wrap justify-center border-4 my-4 bg-gray-100">
                    
                    <Option name='Option 1' cost='18'>
                    Sandwich and 2-3 Salads or Soup
                    </Option>

                    <Option name='Option 2' cost="17">
                    Frittata and 2-3 Salads or Soup
                    </Option>

                    <Option name='Option 3' cost="18">
                    2-3 Salads and Soup
                    </Option>

                    <Option name='Option 4' cost="18">
                    Bowls
                    </Option>

                    <Option name='Custom Menu' cost="TBD">
                    Talk to Bridget! We do special menus for all occasions. 
                    </Option>
                </div>

                <div class="text-3xl font-header font-bold">
                    Order
                </div>

                <div class="border-4 my-4 font-header text-xl">
                    <CateringOrderForm></CateringOrderForm>
                </div>
            </div>
        </div>
    )
}

export default Catering