import React from "react"
import { Link } from "react-router-dom"
import { useState } from "react"
import {ChevronRight} from "lucide-react"
import { ChevronDown } from "lucide-react"
import { useViewportSize } from "@mantine/hooks"

const MenuNav = ({menus}) => {

    const [current, setCurrent] = useState("Salads")
    const [isMenuDown, toggleMenu] = useState(false)
    const { width } = useViewportSize()
    const isMobile = width < 768

    const selectMenu = (name) =>{
        setCurrent(name)
        toggleMenu(!isMenuDown)
    }

    return (
        <div class="flex flex-col md:flex-row justify-center font-nav text-2xl p-10 gap-4">
            <div class="px-4 flex flex-row justify-center font-bold bg-gray-200 md:hidden" onClick={() => toggleMenu(!isMenuDown)}> 
                <div>{current}</div>
                <div class="flex flex-col justify-center">
                    {isMenuDown ? <ChevronDown class="md:hidden"></ChevronDown> :<ChevronRight class="md:hidden"></ChevronRight>}
                </div>
            </div>
            {!!(isMenuDown | !isMobile) && menus.map((menus) => (
                <Link to={menus.link} onClick={() => selectMenu(menus.which)}>
                    <div class="px-4 hover:font-bold"> 
                        {menus.which}
                    </div>
                </Link>
            ))}
        </div>
        
    )
}

export default MenuNav