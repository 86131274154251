const Soups = () => {
    return (
        <div>
            <div class="flex flex-row justify-center">
                <div class="font-header text-3xl">Call to ask about our daily soups!</div>
            </div>
        </div>
    )
}

export default Soups