import React from "react"
import { useRef, useState, useEffect } from "react"

const Review = (props) => {
    return (
        <a class="odd:ml-20 even:mr-20" href={props.href} target="_blank">
            <div class="flex flex-row justify-between text-center bg-white/75 shadow-2xl rounded-xl flex-shrink p-8 gap-6">
            {props.img && (
                <img class="h-full object-contain w-1/2 hidden md:block" src={props.img} alt='food representation'/>
                )}
                {props.children}
            </div>
        </a>
    )
}


const About = ()=>{
    const backgroundRef = useRef(null);
    const [backgroundHeight, setBackgroundHeight] = useState("auto");

    useEffect(() => {
        if (backgroundRef.current) {
        setBackgroundHeight(`${backgroundRef.current.offsetHeight}px`);
        }
    }, []);

    return (
        <div class="flex flex-col h-auto overflow-hidden">
            <div class="absolute flex flex-row justify-end z-0">
                <img class="w-full h-auto object-contain" src={require('../media/storefront.jpg')}></img>
            </div>

            <div class="flex lg:flex-row flex-col z-10 justify-between">
                <div class="flex flex-col p-4 lg:p-20 lg:basis-1/2 gap-8" >
                <Review href="https://maps.app.goo.gl/r1uhgWTXmidM1iUM9">
                    "We ate at by By The Bridge while on vacation. The food was stunning both visually and on the pallette. We had children ranging from 5-14 and they were able to please everyone. We had a gluten free roast beef wrap and a peanut chicken sandwich with sides of vermicelli pasta, chick pea salad and ginger lime slaw. It is such a pleasure to find restauranteurs with such a passion for awesome food." -Google Review
                </Review>
                
                <Review href="https://maps.app.goo.gl/qFUiaGp7fUffUpVC9" img={require('../media/sammyreview.png')}>
                    "This is a gem of a lunch place! I had a small salad and was able to try three different salads of the 8 or 10 on offer. I chose a curried chick pea, an Asian noodle and a maple and balsamic glazed beets. All three were delightful, full of flavor and fresh ingredients. I topped that off with chocolate diablo cookies-ginger, cayenne and rock salt-wow!" 
                    -Google Review
                </Review>

                <Review href="https://maps.app.goo.gl/bc8ZVE2JGGAnsGHT9" img={require('../media/quesadillareview.png')}>
                    "Such wholesome and delicious food! (Butternut squash and pesto quesadilla)" 
                    -Google Review
                </Review>
                </div>
                
                {/* <div class="flex flex-col bg-white/75 rounded-xl z-20 lg:basis-1/2 m-4 lg:m-20 p-4">
                    <div class="w-full text-3xl font-header text-center">
                        By the Bridge
                    </div>
                </div> */}
            </div>
            
        </div>
        
    )
}

export default About
