import { useState } from "react";
import emailjs from "emailjs-com";

export default function CateringOrderForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    eventDate: "",
    orderDetails: "",
  });

  const [status, setStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_p8qo3e7", // Replace with your EmailJS service ID
        "template_f0quj6f", // Replace with your EmailJS template ID
        formData,
        "yQgcRUSYko6WInVE0" // Replace with your EmailJS public key
      )
      .then(
        () => {
          setStatus("Order Request sent successfully! If you don't hear back from us, try emailing justbridget@outlook.com or calling us at 7057755050.");
          setFormData({ name: "", email: "", phone: "", eventDate: "", orderDetails: "" });
        },
        (error) => {
          console.error("EmailJS Error:", error);
          setStatus("Something went wrong. Please try again.");
        }
      );
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
        <div className="bg-white shadow-xl rounded-2xl p-8 w-full max-w-lg">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
            Catering Request Form
            </h2>
            {status && <p className="text-center text-green-600">{status}</p>}
            <form className="space-y-4" onSubmit={handleSubmit}>
            {/* Name */}
            <div>
                <label className="block text-gray-700 font-medium mb-1">Full Name</label>
                <input
                name="name"
                onChange={handleChange}
                value={formData.name}
                type="text"
                placeholder="Enter your name"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-lime-500"
                />
            </div>

            {/* Email */}
            <div>
                <label className="block text-gray-700 font-medium mb-1">Email</label>
                <input
                name="email"
                onChange={handleChange}
                value={formData.email}
                type="email"
                placeholder="Enter your email"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-lime-500"
                />
            </div>

            {/* Phone Number */}
            <div>
                <label className="block text-gray-700 font-medium mb-1">Phone</label>
                <input
                name="phone"
                onChange={handleChange}
                value={formData.phone}
                type="tel"
                placeholder="Enter your phone number"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-lime-500"
                />
            </div>

            {/* Event Date */}
            <div>
                <label className="block text-gray-700 font-medium mb-1">Event Date</label>
                <input
                name="eventDate"
                onChange={handleChange}
                value={formData.eventDate}
                type="date"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-lime-500"
                />
            </div>

            {/* Order Details */}
            <div>
                <label className="block text-gray-700 font-medium mb-1">Order Details</label>
                <textarea
                rows="4"
                name="orderDetails"
                onChange={handleChange}
                value={formData.orderDetails}
                placeholder="Describe your catering order"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-lime-500"
                ></textarea>
            </div>

            {/* Submit Button */}
            <button
                type="submit"
                className="w-full bg-lime-600 text-white font-semibold p-3 rounded-lg hover:bg-lime-700 transition duration-300"
            >
                Submit Order
            </button>
            </form>
        </div>
    </div>
  );
}